<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            <span class="mt-n6">Left Student</span>
            <v-spacer></v-spacer>
            <v-text-field
              hint="At least 3 characters"
              flat
              color="primary"
              class="pa-0"
              label="Search by Name"
              v-model="search"
              append-icon="search"
              outlined
              dense
              @input="searchStudent"
            />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td class="text-center">{{ index + form.items.meta.from }}</td>
                <td class="text-center">
                  <img :src="item.image" style="width: 100%" />
                </td>
                <td class="text-xs-left">{{ item.enroll_code }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.roll }}</td>
                <td class="text-xs-left">
                  {{ item.grade }}<sup>"{{ item.section }}"</sup>
                </td>
                <td class="text-xs-left">{{ item.deleted_at }}</td>
                <td class="text-xs-left">
                  {{ item.remarks ? item.remarks.toUpperCase() : "-" }}
                </td>
                <td class="text-xs-left">
                  <edit-button
                    icon="autorenew"
                    @agree="recoverDialog = true"
                    :permission="'student-delete'"
                    label="Recover Student"
                  ></edit-button>
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="recoverDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Recover Student</span>
        </v-card-title>
        <v-card-text>
          <br />
          <alert-message
            title="Recovery"
            :extraSmall="true"
            message="Please input student's enroll code in the below input field"
          ></alert-message>

          <br />
          <v-text-field
            hint="At least 3 characters"
            flat
            color="primary"
            class="pa-0"
            label="Student's Enroll Code"
            v-model="search"
            append-icon="autorenew"
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-text class="pb-1 pt-4">
          <v-flex xs12>
            <template>
              <div style="color:red;">
                <v-icon small color="orange">error_outline</v-icon>
                <span style="color:orange"> Please Note</span>
                <strong></strong>
                <p style="line-height: 10px">
                  <small style="color:#666;font-size: 10px;">
                    The rank number provided should be a in hierarchical order
                    <br />
                    according to the grade level.
                  </small>
                </p>
              </div>
            </template>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="recoverDialog = false">
            Close
          </v-btn>
          <v-btn color="success" text @click="validate">Recover Now</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
//15,00000
export default {
  mixins: [Mixins],

  data: () => ({
    recoverDialog: false,
    form: new Form(
      {
        title: "",
      },
      "/api/left-student"
    ),
    search: "",

    headers: [
      { text: "SN", align: "center", value: "id", width: 80 },
      { text: "Photo", align: "left", value: "photo", width: 100 },
      { text: "Enroll Code", align: "left", value: "enroll_code" },
      { text: "Name", align: "left", value: "name" },
      { text: "Roll NO", align: "left", value: "roll" },
      { text: "Grade", align: "left", value: "grade" },
      {
        text: "Deleted Date",
        align: "left",
        value: "deleted_at",
        sortable: false,
      },
      { text: "Remarks", align: "left", value: "remarks", sortable: false },
      { text: "Action", align: "right", sortable: false, width: 100 },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
  },
  watch: {
    pagination: function() {
      this.get();
    },
  },

  methods: {
    get(params) {
      let extraParams = "search=" + this.search.toString();

      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;

      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    searchStudent() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.notice_form {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
    color: white;
  }
}
</style>
